<template>
  <empty-card :title="$t('business.qr')">
    <q-r-form />
  </empty-card>
</template>

<script>
import EmptyCard from '@/@bya3/components/cards/EmptyCard'
import QRForm from '@/views/theme/components/QRForm'

export default {
  name: 'QRGenerator',
  components: {
    QRForm,
    EmptyCard,
  },
}
</script>
