import { saveAs } from 'file-saver'
import axios from 'axios'
import $http from '@/libs/axios'
import FormsService from '@/services/forms-service'

class QRAPIs {
    static cancelTokenSource: any = null

    public static async download(data: any, name: string) {
      const body = FormsService.convertObjectToFormData(data)

      const file = (await $http.post('qr/download-tables', body, { responseType: 'blob' })).data
      saveAs(file, name)
    }

    static async previewQR(setting: any) {
      try {
        if (QRAPIs.cancelTokenSource !== null) {
          QRAPIs.cancelTokenSource.cancel()
        }
        QRAPIs.cancelTokenSource = axios.CancelToken.source()

        const body = FormsService.convertObjectToFormData(setting)
        const { data } = await $http.post('/qr/preview', body, { cancelToken: QRAPIs.cancelTokenSource.token })
        QRAPIs.cancelTokenSource = null
        return data.qr
      } catch (error) {
        if (!axios.isCancel(error)) {
          throw error
        }
        return null
      }
    }

    static async downloadQR(download = true) {
      const file = (await $http.get('download-custom-qr', { responseType: 'blob' })).data
      if (download) {
        saveAs(file, 'custom-qr.pdf')
      }
      return file
    }

    static async printQR() {
      const file = await QRAPIs.downloadQR()
      return URL.createObjectURL(file)
    }
}

export default QRAPIs
