<template>
  <div>
    <b-row
      v-if="domain"
      class="justify-content-between mt-3"
    >
      <b-col md="5">
        <ok-text-input :label="$t('qr.color')">
          <ok-color-picker
            v-model="color"
          />
        </ok-text-input>
        <ok-text-input :label="$t('qr.add-logo')">
          <logo-file-input
            ref="logo-input"
            v-model="logo"
            small
          />
        </ok-text-input>
      </b-col>
      <b-col
        class="d-flex align-items-center flex-column"
        md="5"
      >
        <img
          v-if="qrPreview"
          :class="showActions?'w-50':'w-75'"
          :src="'data:image/png;base64, '+qrPreview"
          alt="QR"
        >
        <div
          v-if="qrPreview && showActions"
          class="d-flex mt-2"
        >
          <b-button
            variant="primary"
            @click="handlePrint"
          >
            <FeatherIcon
              class="mr-50"
              icon="PrinterIcon"
            />
            {{ $t("qr.print") }}
          </b-button>
          <b-button
            class="mx-1"
            variant="success"
            @click="handleDownload"
          >
            <FeatherIcon
              class="mr-50"
              icon="DownloadIcon"
            />
            {{ $t("qr.download") }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <div
      v-else
      class="d-flex justify-content-center align-items-center"
      style="height: 300px"
    >
      <p class="h4">
        {{ $t('qr.domain-required') }}
      </p>
    </div>
  </div>
</template>

<script>
import { BButton, BCol, BRow } from 'bootstrap-vue'

import OkColorPicker from '@/@bya3/components/inputs/OkColorPicker'
import debounce from 'lodash/debounce'
import QRAPIs from '@/api/qr-api'
import LogoFileInput from '@/@bya3/components/inputs/LogoFileInput'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import printJS from 'print-js'
import { saveAs } from 'file-saver'
import { mapGetters } from 'vuex'

export default {
  name: 'QRForm',
  components: {
    OkTextInput,
    LogoFileInput,
    OkColorPicker,
    BRow,
    BCol,
    BButton,
  },
  props: {
    isForTable: {
      type: Boolean,
      default: false,
    },
    table: {
      type: Object,
      default: () => {
      },
    },
    isHotel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      color: '#000000',
      logo: null,
      qrPreview: null,
      printing: false,
      downloading: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    showActions() {
      return !this.isForTable || !!this.table?.id
    },
    hasTable() {
      return this.isForTable && !!this.table?.id
    },
  },
  watch: {
    color() {
      this.preview()
    },
    logo() {
      this.preview()
    },
  },
  mounted() {
    this.fetchQr()
  },
  methods: {
    preview: debounce(function () {
      this.fetchQr()
    }, 300),
    businessName() {
      if (this.user?.business_name) return `${this.user?.business_name} `
      return ''
    },
    domain() {
      return this.user?.domain ?? ''
    },
    async fetchQr() {
      const form = {
        color: this.color,
      }
      if (this.logo) {
        form.logo = this.logo
      }
      if (this.hasTable) {
        form.table_id = this.table?.id
        form.is_room = this.isHotel
      }
      await QRAPIs.previewQR(form)
        .then(qr => {
          this.qrPreview = qr
        })
        .catch(e => console.log(e.message))
    },
    handlePrint() {
      printJS({
        printable: `data:image/png;base64, ${this.qrPreview}`,
        type: 'image',
        documentTitle: `${this.businessName()}QR`,
      })
    },
    async downloadZip(branchId) {
      const form = {
        color: this.color,
        branch_id: branchId,
        all: 1,
        is_room: this.isHotel,
      }
      if (this.logo) {
        form.logo = this.logo
      }
      const name = `${this.businessName()}-${branchId}-${this.isHotel ? 'rooms' : 'tables'}`
      await QRAPIs.download(form, name).catch(() => {
        this.$notifyError(this.$t('qr.not-found'))
      })
    },
    print() {
      this.printing = true
      QRAPIs.printQR().then(printable => printJS({ printable, type: 'pdf' })).finally(() => {
        this.printing = false
      })
    },
    download() {
      this.downloading = true
      QRAPIs.downloadQR().finally(() => {
        this.downloading = false
      })
    },
    convertBase64ToFile(base64String, fileName) {
      const arr = base64String.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const uint8Array = new Uint8Array(n)
      while (n) {
        n -= 1
        uint8Array[n] = bstr.charCodeAt(n)
      }
      return new File([uint8Array], fileName, { type: mime })
    },
    handleDownload() {
      let name = this.businessName()
      if (this.hasTable) {
        name = `${this.table.branch}-${this.table.number}-`
      }
      const fileName = `${name}QR.png`
      const linkSource = `data:image/png;base64, ${this.qrPreview}`
      const file = this.convertBase64ToFile(linkSource, fileName)
      saveAs(file, fileName)
    },
  },
}
</script>
